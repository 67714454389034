






import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component
export default class DaoAdminGuard extends Vue {
  @Prop({default: true}) isAdmin!: boolean
}
